import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';

const API_URL = 'https://sport-api.luncky.com';
//const API_URL = 'http://127.0.0.1:8787';
const TOKEN_NAME = 'sport_token';

let cookie;

function App() {
  const [token, setToken] = useState();
  const [login, setLogin] = useState(false);
  const [captcha, setCaptcha] = useState();
  const [booked, setBooked] = useState([]);
  const captchaRef = useRef();
  const dateRef = useRef();

  const query = useCallback((path, options = {}) => {
    const headers = token ? {Authorization: token, 'X-Cookie': cookie} : {};
    return new Promise((resolve) => {
      fetch(API_URL + path, Object.assign({}, {headers}, options))
        .then(resp => {
          resolve(resp.json());
        });
    });
  }, [token]);

  const onSignIn = useCallback((event) => {
    const headers = {
      Authorization: event.detail.data.credential,
    };
    query('/token', {headers})
      .then(json => {
        event.detail.callback();
        window.sessionStorage.setItem(TOKEN_NAME, json.token);
        setToken(json.token);
      });
  }, [query]);

  const onLogin = useCallback(() => {
    const body = {
      captcha: captchaRef.current.value,
    };
    query('/login', {method: 'POST', body: JSON.stringify(body)})
      .then(json => {
        if (json.login === true)
          setLogin(true);
        else
          captchaRef.current.value = '';
      });
  }, [query]);

  const onToggle = (time, place) => {
    setBooked(data => [
      ...data.slice(0, time),
      [
        ...data[time].slice(0, place),
        !data[time][place],
        ...data[time].slice(place + 1),
      ],
      ...data.slice(time + 1),
    ]);
  };

  const onBook = useCallback(() => {
    const placeIds = [83, 84, 1074, 1075];
    const book = [];
    for (let i = 0; i < booked.length; ++i) {
      for (let j = 0; j < 4; ++j)
        if (booked[i][j]) {
          const b = {time: i + 12, place: placeIds[j]};
          book.push(b);
        }
    }
    if (book.length === 0)
      return;

    const body = {
      date: dateRef.current.value,
      book,
    };
    query('/book', {method: 'POST', body: JSON.stringify(body)})
      .then(json => {
      });
  }, [booked, query]);

  useEffect(() => {
    window.addEventListener('signin', onSignIn);
  }, [onSignIn]);

  useEffect(() => {
    if (!token)
      return;

    if (!cookie) {
      const pool = 'abcdefghijklmnopqrstuvwxyz012345';
      cookie = 'ASP.NET_SessionId=';
      for (let i = 0; i < 24; ++i)
        cookie += pool[Math.floor(Math.random() * pool.length)];
    }

    const headers = {
      Authorization: token,
      'X-Cookie': cookie,
    };
    fetch(API_URL + '/captcha', {headers})
      .then(resp => resp.blob())
      .then(blob => {
        setCaptcha(URL.createObjectURL(blob));
      });
  }, [token]);

  useEffect(() => {
    const token = window.sessionStorage.getItem(TOKEN_NAME);
    if (token)
      setToken(token);

    const times = [];
    for (let i = 12; i < 22; ++i) {
      const places = [];
      for (let j = 0; j < 4; ++j) {
        places.push(false);
      }
      times.push(places);
    }
    setBooked(times);
  }, []);

  const renderLogin = () => {
    return (
      <>
        <div className="row"><img src={captcha} alt='captcha'></img></div>
        <div className="row">Captcha <input type='text' autoComplete='off' autoFocus ref={captchaRef} /></div>
        <div className="row"><button onClick={onLogin}>登入</button></div>
      </>
    );
  };

  const renderBook = () => {
    const day = ['日', '一', '二', '三', '四', '五', '六'];
    const today = new Date().getTime();
    let t = today + 86400 * 1000 * 8;
    const dates = [];
    for (let i = 0; i < 7; ++i) {
      dates.push(t);
      t += 86400 * 1000;
    }
    return (
      <>
        <div className="row">日期 <select ref={dateRef}>
        {
          dates.map((date, index) => {
            const d = new Date(date);
            const mm = `0${d.getMonth() + 1}`.slice(-2);
            const dd = `0${d.getDate()}`.slice(-2);
            const str = `${d.getFullYear()}-${mm}-${dd}`;
            const selected = index === dates.length - 1;
            return <option key={index} value={str} selected={selected}>{`${str} (${day[d.getDay()]})`}</option>
          })
        }
        </select></div>
        <table className="row">
          <thead><tr><th>A</th><th>B</th><th>C</th><th>D</th></tr></thead>
          <tbody>
          {
            booked.map((places, i) => {
              const t = `${i + 12}:00`;
              return (
                <tr key={i}>
                {
                  places.map((b, j) => {
                    let className = "toggle";
                    if (b)
                      className += " checked";
                    return <td key={j} className={className} onClick={() => {onToggle(i, j);}}>{t}</td>
                  })
                }
                </tr>
              );
            })
          }
          </tbody>
        </table>
        <div className="row"><button onClick={onBook}>預定場地</button></div>
      </>
    );
  };

  let content;
  if (token) {
    if (!login)
      content = renderLogin();
    else
      content = renderBook();
  }

  return (
    <div className="App">
      <div className="header">Sport</div>
      <div className="content">
        {content}
      </div>
    </div>
  );
}

export default App;
